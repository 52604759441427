import Pjax from './vendor/pjax.min';

const NAV_EXPAND = 'nav-expand';
const navExpandElement = document.getElementById(NAV_EXPAND);

function hideNavMenu() {
    navExpandElement.checked = false;
}

// Enable loading partial pages with AJAX on explicitly marked links.
// This allows the links to function as expect when Javascript is not available,
// but saves data and provides a smoother visual experience when it can.
const pjax = new Pjax({
    // Not going to apply it to all links
    elements: 'a[data-pjax]',
    // Changes will be applied to any
    selectors: ['#content'],
    switches: {
        // Allows us to animate the transition by maintaining both sets of content
        '#content': Pjax.switches.sideBySide
    },
    switchesOptions: {
        '#content': {
            classNames: {
                // Fade out the existing content with no movement (caused scrollbars).
                remove: 'animated fadeOut zBack',
                // New content will slide into place from the left side of the screen.
                add: 'animated fadeIn animate__delay-4s',
            }
        }
    },
    // We'll send out a Cache-Control header instead. Prevents adding a lot of
    // timestamped views to the user's history.
    cacheBust: false
});

// Helper to dismiss the navigation menu when an item is clicked.
document.addEventListener('pjax:send', hideNavMenu);

// Helper to dismiss the navigation menu when a click outside the menu is
// performed. If Javascript/PJAX is not enabled, then normal navigation will
// happen and, effectively, close the menu.
document.addEventListener('click', function (event) {
    const pathTags = event.path.map(it => it.localName);
    if (!pathTags.includes('header') && navExpandElement.checked) {
        hideNavMenu();
    }
});

document.addEventListener('keyup', function (event) {
    if (event.key === 'Escape') {
        // This check is less about not calling hideNavMenu when it's already
        // hidden and more about allowing the keypress to propagate properly.
        if (navExpandElement.checked) {
            hideNavMenu();
            event.stopPropagation();
            return;
        }
    }
});

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
        });
    });
});

// I'm not including modernizr for one feature.
document.querySelectorAll('.no-js').forEach(it => {
    it.classList.remove('no-js');
});
